import NiceSelect from "nice-select2";

const mountSelects = (selectElements) => {
    let instanceSelects = [];
    if (selectElements.length > 0) {

        for (const select of selectElements) {

            if (select.style.opacity === '0') return; // mounted

            select.addEventListener('change', function(){
                let nice = this.closest('.form-control-wrap').querySelector('.nice-select');
                if(nice){
                    let val = this.value;
                    if(val){
                        nice.classList.add('selected');
                    }
                    else{
                        nice.classList.remove('selected');
                    }
                }
            });
            
            let searchable = false;
            let searchtext = select.getAttribute('search');
            if(searchtext){
                searchable = true;
            }
            instanceSelects.push( new NiceSelect(select, {
                placeholder: select.dataset.placeholder ?? '',
                searchable: searchable,
                searchtext: searchtext
            }) );
        }
    }
    return instanceSelects;
}

const resetMountSelect = (field) => {
    if(field && field.classList.contains('form-control-select')){
        let fieldWrap = field.closest('.form-control-wrap');

        if(fieldWrap){

            field.value = '';
            field.dispatchEvent(new Event('change')); // trigger event change
    
            
            let listItems = fieldWrap.querySelectorAll('.nice-select .list .option');
            if(listItems.length>0){
                listItems.forEach((el)=>{
                    el.classList.remove('selected');
                    el.classList.remove('focus');
                });
            }

            let current = fieldWrap.querySelector('.nice-select .current');
            let placeholder = field.getAttribute('placeholder');
            if(placeholder && current){
                current.innerHTML = placeholder;
            } 
        }

    }
}

const setFormFieldError = (field, message) => {
    if (!field) return;
    let fieldWrap = field.closest('.form-group');
    if (fieldWrap) {
        fieldWrap.classList.add('error');
        let el = fieldWrap.querySelector('.error-msg');
        if(el){
            el.innerHTML = message;
        }
        else{
            const p = document.createElement('p');
            p.classList.add('error-msg');
            p.innerHTML = message;
            fieldWrap.appendChild(p);
        }
    }
}

const removeFormFieldError = (field) => {
    if (!field) return;
    let fieldWrap = field.closest('.form-group');
    if(fieldWrap){
        fieldWrap.classList.remove('error');
        let errorMsg = fieldWrap.querySelector('.error-msg');
        if(errorMsg){
            errorMsg.remove();
        }
    }
}

const removeFormFieldsError = (form, fields) => {
    for (const [key, value] of Object.entries(fields)) {
        if (key=='action') continue;
        if (key=='grecaptchaResponse') {
            let field = form.querySelector(`.g-recaptcha`);
            if (!field) continue;
            removeFormFieldError(field);
            continue;
        }
        let field = form.querySelector(`[name="${key}"]`);
        if (!field) continue;
        removeFormFieldError(field);
    }
}


const clearFormField = (form, fields) => {
    for (const [key, value] of Object.entries(fields)) {
        if (key=='action') continue;
        if (key=='phone_code') continue;
        if (key=='landline_code') continue;
        if (key=='grecaptchaResponse') {
            if(grecaptcha){
                grecaptcha.reset();
            }
            continue;
        }
        else{
            let field = form.querySelector(`[name="${key}"]`);
            if(field){
                if(field.classList.contains('form-control-radio') || field.classList.contains('form-control-checkbox')){
                    let formWrap = field.closest('.form-control-wrap');
                    if(formWrap){
                        let fields = formWrap.querySelectorAll('.form-control');
                        if(fields.length>0){
                            fields.forEach(el => {
                                el.checked = false;
                            });
                        }
                    }
                }
                else if(field.classList.contains('form-control-file')){
                    let formWrap = field.closest('.form-control-wrap');
                    if(formWrap){
                        let filesItems = formWrap.querySelector('.selected-files-items');
                        if(filesItems){
                            filesItems.innerHTML = '';
                        }
                    }
                }
                else if(field.classList.contains('form-control-select')){
                    resetMountSelect(field);
                }
                else{
                    field.value = '';
                    if(key=='term'){
                        field.checked = false;
                    }
                    // else if(key=='phone_code'){
                    //     let label = field.getAttribute('placeholder');
                    //     let nice = field.closest('.form-control-wrap').querySelector('.nice-select');
                    //     let niceCurrent = nice.querySelector('.nice-select .current');
                    //     let option = nice.querySelector('.option.selected');
                    //     if(nice){
                    //         nice.classList.remove('selected');
                    //     }
                    //     if(niceCurrent){
                    //         niceCurrent.innerHTML = label;
                    //     }
                    //     if(option){
                    //         option.classList.remove('selected');
                    //         option.classList.remove('focus');
                    //     }
                    // }
                }
            }
        }
    }
}

function handleFiles(input, files, multiple=false, removeSelector='.js-remove-file-item') {
    if(files){
        let selected_item = '';
        for(let i=0; i<files.length; i++){
            selected_item +=
            `<div class="item">
                <div class="item-inner">
                    <div class="title">${files[i].name}</div>
                    <div class="icon js-remove-file-item" data-index="${i}">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 5.99884L5.9988 14.0012" stroke="#1A1A1A" stroke-linecap="square" stroke-linejoin="round"/>
                            <path d="M14 14.0012L5.9988 5.99878" stroke="#1A1A1A" stroke-linecap="square" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>`;
            if(!multiple){
                break;
            }
        }
        let formWrap = input.closest('.form-control-wrap');
        if(formWrap){
            let filesItems = formWrap.querySelector('.js-selected-files-items');
            if(filesItems){
                filesItems.innerHTML = selected_item;
            }
        }
    }
    else{
        let formWrap = input.closest('.form-control-wrap');
        if(formWrap){
            let filesItems = formWrap.querySelector('.js-selected-files-items');
            if(filesItems){
                filesItems.innerHTML = '';
            }
        }
    }

    addEventListenerRemoveFileItem(removeSelector);
}

function removeFileFromFileList(input, index) {
    const dt = new DataTransfer();
    let files = input.files ?? '';
    if(files.length>0){
        for(let i=0; i<files.length; i++){
            if(i!=index){
                dt.items.add(files[i]);
            }
        }
    }
    input.files = dt.files;
    handleFiles(input, input.files, true);
}

function addEventListenerRemoveFileItem(selector){
    const items = document.querySelectorAll(selector);
    if(items.length>0){
        items.forEach((el)=>{
            el.addEventListener('click', function(){
                if(el.classList.contains('form-control-file-multiple')){
                    let formWrap = el.closest('.form-control-wrap'); 
                    if(formWrap){
                        let formControl = formWrap.querySelector('.form-control');
                        if(formControl){
                            let index = Number(el.getAttribute('data-index'));
                            removeFileFromFileList(formControl, index);
                        }
                    }
                }
                else{
                    let formWrap = el.closest('.form-control-wrap');
                    if(formWrap){
                        let formControl = formWrap.querySelector('.form-control');
                        if(formControl){
                            formControl.value = '';
                        }
                        let fileItems = formWrap.querySelector('.selected-files-items');
                        if(fileItems){
                            fileItems.innerHTML = '';
                        }
                    }
                }
            });
        });
    }
}

export { mountSelects, setFormFieldError, removeFormFieldsError, clearFormField, handleFiles, removeFileFromFileList }